import "../scss/_modal.scss";

(function ($) {
    //Declare in Global Namespace	
    window.RDG = window.RDG || {};
    window.RDG.Modal = {};

    // set default configurations
    var configurations = {
        name: "Fundraising Modal",
        version: 1.0,
        delay: 0,
        openModalDelay: 500,
        iframeResizeDelay: 100,
        resizeIframeHeightBuffer: 5,
        allowMultipleClass: 'allow-multiple'
    };
    
    function handleModaliFrameResize() {
        var hasIframe = document.querySelector('.iframe-wrapper') ? true : false;
        if (hasIframe) {
            var iframe = document.querySelector('.iframe-wrapper').querySelectorAll('iframe')[0];
            RDG.Modal.resizeIframe(iframe);
        }
    }

    function setOverlayTrigger() {
        $(document).on("click", ".rdg-modal", function (e) {
            //Stop default action and scroll to top
            e.preventDefault();
            const $this = $(this);
            const modalId = "rdg-modal";
            const modalTitle = $this.data('modal-title');
            const modalSize = $this.data('modal-size') ? $this.data('modal-size') : '';
            const modalDelay = $this.data('modal-delay') ? $this.data('modal-delay') : null;
            const modalBackground = $this.data('modal-background') ? $this.data('modal-background') : 'true';
            const allowMultipleModal = $this.hasClass(RDG.Modal.config.allowMultipleClass);
            const iframeUrl = $this.data("modal-url") !== undefined ? $this.data("modal-url") : $this.attr('href');   
            RDG.Modal.create(modalId, modalTitle, iframeUrl, modalSize, null, modalBackground, modalDelay, allowMultipleModal);
            return false;
        });

        $(document).on("click", "button.close", (e) => {
            RDG.Modal.Close();
        });


        window.RDG.Modal.Close = function () {
            $('.modal.show').modal('hide');
        };

        $(document).on("click", ".close-iframe-modal", function (e) {
            e.preventDefault();
            window.parent.RDG.Modal.Close();
            if (typeof RDG.Util !== "undefined" && typeof RDG.Util.stopMediaPlayer !== "undefined") RDG.Util.stopMediaPlayer();
        });
    }

    RDG.Modal = {
        config: configurations,
        init: function () {
            setOverlayTrigger();
        },
        create: function (modalId, modalTitle, contentSrc, size, isInline, backdrop, delay, allowMultiple, callback) {
            if (typeof modalId === "undefined") modalId = 'modal-overlay';
            if (typeof modalTitle === "undefined") modalTitle = null;
            if (typeof contentSrc === "undefined") contentSrc = null;
            if (typeof size === "undefined" || size === null) size = "";
            if (typeof backdrop === "undefined") backdrop = true;
            if (typeof delay === "undefined" || delay === null) delay = RDG.Modal.config.delay;
            if (typeof allowMultiple === "undefined") allowMultiple = false;

            // Check for open modals and close if open
            const modalIsOpen = $('body').hasClass('modal-open') ? true : false;

            if (modalIsOpen && !allowMultiple) {
                RDG.Modal.close();
                delay = RDG.Modal.config.openModalDelay;
            }

            window.setTimeout(function () {
                // Modal Container
                const $modalContainer = $('<div/>', {
                    id: modalId,
                    'class': 'modal fade dynamic',
                    'role': 'dialog',
                    'aria-hidden': true
                });

                // Modal Dialog
                var $modalDialog = $('<div/>', {
                    'class': 'modal-dialog modal-dialog-centered ' + size,
                    'role': 'document'
                });

                $modalContainer.append($modalDialog);

                // Modal Content
                var $modalContent = $('<div/>', {
                    'class': 'modal-content'
                });

                $modalDialog.append($modalContent);

                // Modal Header
                var $modalHeader = $('<div/>', {
                    'class': 'modal-header'
                });

                $modalContent.append($modalHeader);

                if (modalTitle) {
                    // Modal Header Title
                    var $modalTitle = $('<h5/>', {
                        'class': 'modal-title',
                        id: modalId + '_title'
                    }).html(modalTitle);
                    $modalHeader.append($modalTitle);
                }

                // Modal Close Button
                var $closeButton = $('<button/>', {
                    'type': 'button',
                    'class': 'close',
                    'data-dismiss': 'modal',
                    'aria-label': 'Close'
                });

                $modalHeader.append($closeButton);

                // Modal Close Icon
                var $closeIcon = $('<span />', {
                    'aria-hidden': true
                }).html('&times');

                $closeButton.append($closeIcon);

                // Modal Content Body

                var $modalBody = $('<div/>', {
                    id: modalId + '-body',
                    'class': 'modal-body'
                });

                $modalContent.append($modalBody);

                $modalBody.addClass('iframe-content');

                var $iFrameWrapper = $('<div/>', {
                    'class': 'iframe-wrapper'
                });

                // Modal iFrame Content
                var modaliFrame = document.createElement("iframe");
                modaliFrame.id = "iframe-" + modalId;
                modaliFrame.src = contentSrc;
                modaliFrame.frameborder = 0;
                modaliFrame.width = '100%';
                modaliFrame.setAttribute('allowFullScreen', 'true');
                modaliFrame.setAttribute('webkitallowfullscreen', 'true');
                modaliFrame.setAttribute('mozallowfullscreen', 'true');
                modaliFrame.setAttribute('allowtransparency', 'true');

                modaliFrame.onload = function () {
                    var iframeRef = this;
                    RDG.Modal.resizeIframe(iframeRef, RDG.Modal.config.iframeResizeDelay);
                };

                $iFrameWrapper.append($(modaliFrame));
                $modalBody.append($iFrameWrapper);
                window.addEventListener('resize', handleModaliFrameResize);
                

                // Attach everything together and to the DOM
                $('body').prepend($modalContainer);
                
                // Open new modal
                const $modal = $("#" + modalId);

                $modal.modal("show");

                // Remove this Modal should it ever be closed
                $modal.on('hidden.bs.modal', function (e) {
                    if (modaliFrame) window.removeEventListener('resize', handleModaliFrameResize);
                    $modal.remove();
                });

                if (callback) {
                    callback();
                }

            }, delay);
        },
        resizeModal: function (iframeId) {
            $('.modal').modal('handleUpdate');
        },
        resizeIframe: function (iframe, resizeDelay) {
            if (typeof resizeDelay === "undefined") resizeDelay = 0;
            window.setTimeout(function () {
                try {
                    iframe.height = (iframe.contentWindow.document.body.scrollHeight + RDG.Modal.config.resizeIframeHeightBuffer) + "px";
                } catch (e) {
                    console.log(e);
                }
            }, resizeDelay);
        },
        resizeParentIframe: function (resizeDelay) {
            if (typeof resizeDelay === "undefined") resizeDelay = 0;
            window.setTimeout(function () {
                try {
                    var iframe = parent.document.getElementById(window.frameElement.id);
                    parent.RDG.Modal.resizeIframe(iframe);
                } catch (e) {
                    console.log(e);
                }
            }, resizeDelay);
        },
        close: function () {
            $('.modal').modal('hide');
        }
    };
    RDG.Modal.init();
})(jQuery);