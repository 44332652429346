import '../scss/app.scss';
import './Modal';
var bgModalId = 'bg-overlay';
var bgModalClass = 'bg-overlay';
var bgModalActiveClass = 'active';
var modalTriggerClass = 'video-overlay';
var setBookAppointment = function () {
    var triggers = document.getElementsByClassName('book-appointment-trigger');
    if (triggers) {
        for (var i = 0; i < triggers.length; i++) {
            var trigger = triggers[i];
            trigger.addEventListener('click', function (e) {
                e.preventDefault();
                var appointmentBtn = document.querySelector('.book-appointment-btn');
                appointmentBtn.click();
            });
        }
    }
};
document.addEventListener("DOMContentLoaded", function (event) {
    setBookAppointment();
});
